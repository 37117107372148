export default [{
    path: "/",
    name: "index page",
    meta: {
      title: "index page",
      page: "home",
    },
    component: () => import("../views/index"),
  },
  {
    path: "/alumni",
    name: "alumni page",
    meta: {
      title: "alumni",
      page: "alumni",
      layout: "layoyt4",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/impact",
    name: "impact page",
    meta: {
      title: "impact",
      page: "impact",
      layout: "layoyt4",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/blogDetail/:id?",
    name: "blog detail",
    meta: {
      title: "blog detail",
    },
    component: () => import("../views/blogDetail"),
  },
  {
    path: "/contentDetail/:id?",
    name: "content detail",
    meta: {
      title: "content detail",
    },
    component: () => import("../views/contentDetail"),
  },
  {
    path: "/crowdfunds/:slug?",
    name: "CrowdFunds",
    meta: {
      title: "crowd funds",
    },
    component: () => import("../views/crowdBlog"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: "About",
    },
    component: () => import("../views/about"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      title: "Contact",
      page: 'contact'
    },
    component: () => import("../views/contact"),
  },
  {
    path: "/rollOfhonor",
    name: "rollOfhonor",
    meta: {
      title: "Roll Of Honor",
      page: 'rollOfhonor'
    },
    component: () => import("../views/rollOfhonor"),
  },
  {
    path: "/giving",
    name: "giving",
    meta: {
      title: "Giving",
      page: "giving",
      layout: "layoyt1",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/giving/whyGive",
    name: "whyGive",
    meta: {
      title: "why Give",
      page: "whyGive",
      layout: "layoyt1",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/giving/howToGive",
    name: "howToGive",
    meta: {
      title: "How To Give",
      page: "howToGive",
      layout: "layoyt1",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/giving/givingTheUS",
    name: "givingTheUS",
    meta: {
      title: "Giving The US",
      page: "givingTheUS",
      layout: "layoyt1",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/giving/theEndowment",
    name: "theEndowment",
    meta: {
      title: "The Endowment",
      page: "theEndowment",
      layout: "layoyt1",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/giving/fiendsAndAlumniChapters",
    name: "fiendsAndAlumniChapters",
    meta: {
      title: "FriendsAnd Alumni Chapters",
      page: "fiendsAndAlumniChapters",
      layout: "layoyt2",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/opportunities",
    name: "opportunities",
    meta: {
      title: "opportunities",
      page: "opportunities",
      layout: "layoyt1",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/opportunities/annualFund",
    name: "Annual Fund",
    meta: {
      title: "Annual Fund",
      page: "annualFund",
      layout: "layoyt1",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/opportunities/FinancialAid",
    name: "Financial Aid",
    meta: {
      title: "Financial Aid",
      page: "FinancialAid",
      layout: "layoyt1",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/opportunities/scholarships",
    name: "Scholarships",
    meta: {
      title: "Scholarships",
      page: "scholarships",
      layout: "layoyt1",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/opportunities/sponsorship",
    name: "sponsorship",
    meta: {
      title: "sponsorship",
      page: "sponsorship",
      layout: "layoyt2",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/opportunities/topPriorities",
    name: "topPriorities",
    meta: {
      title: "topPriorities",
      page: "topPriorities",
      layout: "layoyt2",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/opportunities/namingOppurtunities",
    name: "namingOppurtunities",
    meta: {
      title: "namingOppurtunities",
      page: "namingOppurtunities",
      layout: "layoyt1",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/opportunities/legacyGiving",
    name: "legacyGiving",
    meta: {
      title: "legacyGiving",
      page: "legacyGiving",
      layout: "layoyt1",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/crowdfunding",
    name: "crowdfunding",
    meta: {
      title: "crowdfunding",
      page: "crowdfunding",
      layout: "layoyt5",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/auk-day",
    name: "auk-day",
    meta: {
      title: "crowdfunding",
      page: "crowdfunding",
      layout: "layoyt5",
    },
    component: () => import("../views/page"),
  },
  {
    path: "/advancement",
    name: "advancement",
    meta: {
      title: "advancement",
      page: "advancement",
    },
    component: () => import("../views/advancement"),
  },
  {
    path: "/giveNow",
    name: "giveNow",
    meta: {
      title: "Give Now",
      page: "giveNow",
    },
    component: () => import("../views/giveNow"),
  },
  {
    path: "/giveNow/fib",
    name: "giveNow-fib",
    meta: {
      title: "Give through FIB Code",
      page: "giveNow/fib",
    },
    component: () => import("../views/giveNow-fib.vue"),
  },
  {
    path: "/make-a-gift",
    name: "make-a-gift",
    meta: {
      title: "make-a-gift",
      page: "make-a-gift",
    },
    component: () => import("../views/make_gift"),
  },
  {
    path: "/regional",
    name: "regional",
    meta: {
      title: "regional",
      page: "regional",
    },
    component: () => import("../views/regional"),
  },
  {
    path: "/giving/success",
    name: "success",
    meta: {
      title: "success",
      page: "success",
    },
    component: () => import("../views/areeba_success"),
  },
  {
    path: "/giving/cancel",
    name: "cancel",
    meta: {
      title: "cancel",
      page: "cancel",
    },
    component: () => import("../views/areeba_cancel"),
  },
  {
    path: "/giving/error",
    name: "error",
    meta: {
      title: "error",
      page: "error",
    },
    component: () => import("../views/areeba_error"),
  },
  {
    path: "/giving/callback",
    name: "callback",
    meta: {
      title: "callback",
      page: "callback",
    },
    component: () => import("../views/areeba_callback"),
  },



];